import { createContext } from "react"

export const intialContextState = {}
export const MainContext = createContext([{}, () => {}])

export const MENU_ACTIONS = {
  NAVIGATE: "navigate",
  DOWNLOAD_AS_IMAGE: "download-as-image",
  DOWNLOAD_CONTACT: "download-contact",
  PAINT_REDUCER: "paint-reducer",
  SHARE_FB: "share-fb",
  PERFORM_CRAZY_ANIMATION: "perform-crazy-animation",
  SHOW_HELP_WINDOW: "show-help-window",
}
