import React, { useState } from "react"

import { MainContext, intialContextState } from "../config"

function ContextProvider({ children }) {
    const [context, setContext] = useState(intialContextState)
    return <MainContext.Provider value={[context, setContext]}>
        {children}
    </MainContext.Provider>
}

export default ContextProvider