// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-album-js": () => import("./../../src/pages/album.js" /* webpackChunkName: "component---src-pages-album-js" */),
  "component---src-pages-arcade-index-js": () => import("./../../src/pages/arcade/index.js" /* webpackChunkName: "component---src-pages-arcade-index-js" */),
  "component---src-pages-home-js": () => import("./../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */)
}

