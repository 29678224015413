import React from "react"
import ContextProvider from "./src/components/context"
import "./src/styles/global-styles.css"
import "typeface-shrikhand"

export const onInitialClientRender = () => {
  setTimeout(() => {
    const spinDiv = document.querySelector(`.spin`)
    spinDiv && spinDiv.classList.remove("spin")
  }, 1000)
}

export const shouldUpdateScroll = () => false

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
)
